<template>
  <v-container class="login fill-height" align="center" justify="center" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="primary pl-2 pt-1" flat>
          <v-card-title class="white--text pa-2" flat>
            <h2>Le tue info</h2>
            <p class="body-2 white--text pt-2">Rispondi a qualche domanda per migliorare la tua esperienza in TechSoup Together</p>
          </v-card-title>
        </v-card>
        <v-stepper
          v-model="e6"
          vertical
        >
            <v-stepper-step
              :complete="e6 > 1"
              step="1"
            >
              Il tuo ruolo
              <small>Opzionale</small>
            </v-stepper-step>

            <v-stepper-content step="1">
                  <v-responsive
                    class="overflow-visible ma-1 pb-0"
                    max-width="400"
                  >
                    <p class="caption grey--text">Di cosa ti occupi?</p>
                    <v-text-field
                      label="Il tuo ruolo"
                      placeholder="Es. Fundraiser"
                      v-model="userRole"
                      outlined
                    ></v-text-field>
                  </v-responsive>
              <v-btn
                color="primary"
                @click="editUserRole"
                class="mb-2 ml-1"
              >
                Continua
              </v-btn>
              <v-btn @click="e6 = 2" text>
                  Salta
                </v-btn>
            </v-stepper-content>



            <v-stepper-step
              :complete="e6 > 2"
              step="2"
            >
              La tua organizzazione
              <small>Opzionale</small>
            </v-stepper-step>

            <v-stepper-content step="2">
                  <v-responsive
                    class="overflow-visible ma-1 pb-0"
                    max-width="400"
                  >
                    <p class="caption grey--text">Di che organizzazione fai parte?</p>
                    <v-text-field
                      label="Nome dell'organizzazione"
                      placeholder="Es. Social Techno"
                      v-model="orgTitle"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="Codice fiscale organizzazione"
                      placeholder="Es. 12345678911"
                      v-model="orgCodFiscale"
                      outlined
                    ></v-text-field>
                  </v-responsive>
              <v-btn
                color="primary"
                @click="editOrganization"
                class="mb-2 ml-1"
              >
                Continua
              </v-btn>
              <v-btn @click="e6 = 3" text>
                  Salta
              </v-btn>
              <v-btn @click="e6 = 1" text>
              Indietro
              </v-btn>
            </v-stepper-content>



            <v-stepper-step
              :complete="e6 > 3"
              step="3"
            >
              La tua immagine di profilo
              <small>Opzionale</small>
            </v-stepper-step>

            <v-stepper-content step="3">
              <v-responsive
                    class="overflow-visible ma-1 pb-7"
                    max-width="400"
                  >
                    <p class="caption grey--text">Seleziona la tua immagine di profilo.</p>
                    <v-file-input
                      class="pt-0"
                      accept="image/png, image/jpeg, image/bmp"
                      label="Seleziona immagine di profilo"
                      hint="jpg, png"
                      persistent-hint
                      prepend-icon="mdi-camera"
                      v-model="avatarImage"
                    ></v-file-input>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      v-if="uploadingInProgress"
                    ></v-progress-circular>
              </v-responsive>
              <p class="red--text" v-if="warning">{{warning}}</p>
              <div class="mb-2 ml-1">
                <v-btn
                color="primary"
                @click="uploadImage">
                Carica e Continua
                </v-btn>
                <v-btn @click="skip" text>
                  Salta
                </v-btn>
                <v-btn @click="e6 = 2" text>
                Indietro
                </v-btn>
              </div>              
            </v-stepper-content>

            <v-stepper-step
              :complete="e6 > 4"
              step="4"
            >
              Fatto!
            </v-stepper-step>

            <v-stepper-content step="4">
              <h3 class="white primary--text">Grazie per aver completato il tuo profilo!</h3>
              <v-btn
                color="primary"
                @click="goNext"
                :disabled="doneActive == null"
              >
                FATTO
              </v-btn>
              <v-btn @click="e6 = 3" text>
                Indietro
              </v-btn>
            </v-stepper-content>
          </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { fb, usersCollection, storage } from "@/plugins/firebase.js";
import { mapGetters } from "vuex";

export default {
  name: "Onboarding",
  title: "Onboarding",

  data() {
    return {
      e6: null,
      warning: null,
      avatarImage: null,
      uploadingInProgress: null,
      doneActive: null,

      userRole: null,
      orgTitle: null,
      orgCodFiscale: null,
    };
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  methods: {
    async editUserRole(){
      this.e6 = 2

      let dataToSet = {
        role: this.userRole,
      };
      await usersCollection.doc(this.user.id).set(dataToSet, { merge: true });
    },
    async editOrganization(){
      let dataToSet = {
        userOrg: {
          title: this.orgTitle,
          codFiscale: this.orgCodFiscale
        },
      };
      await usersCollection.doc(this.user.id).set(dataToSet, { merge: true });
      
      this.e6 = 3
    },
    async uploadImage(){

      if (this.avatarImage) {

        this.uploadingInProgress = true

        const pathStorage = "gs://" + process.env.VUE_APP_PROJECT_ID + "/";
        const folderUpload = "users/";
        const pathFile = this.user.id + "/" + "avatar";

        const fileRef = fb
          .storage()
          .ref()
          .child(folderUpload + pathFile);
        await fileRef.put(this.avatarImage);
        this.GsUrlOriginal = pathStorage + folderUpload + pathFile;

        // --------------------------------------------------------
        // Step 2 - Update document in database to include image url
        // --------------------------------------------------------

        // wait some seconds to make sure thumbnail is created
        const delay = (ms) => new Promise((res) => setTimeout(res, ms));
        await delay(4000); // wait x seconds

        // get the user public avatar from storage
        let pathAvatarImage =
          "gs://" +
          process.env.VUE_APP_STORAGE_BUCKET +
          "/" +
          "users/" +
          this.user.id +
          "/avatar_200x200";

          // console.log('pathAvatarImage --> ' + pathAvatarImage)

        const urlImage = await storage
          .refFromURL(pathAvatarImage)
          .getDownloadURL()
          .catch((err) => console.log("errore in urlImg" + err));

        const userDataToAdd = {
          avatar_url: urlImage,
        };
        await usersCollection
          .doc(this.user.id)
          .set(userDataToAdd, { merge: true });

        this.e6 = 4
        this.warning = null
        this.uploadingInProgress = null
        this.doneActive = 'go'

      } else {
        this.warning = 'please select an image' 
      }
    },
    skip(){
      this.e6 = 4
      this.doneActive = 'go'
    },
    async goNext(){
      console.log(sessionStorage.getItem('redirectPath'))
      //Go to '/defaultpath' if no redirectPath value is set
      this.$router.push(sessionStorage.getItem('redirectPath') || '/speakers');
      //Cleanup redirectPath
      sessionStorage.removeItem('redirectPath');
    }
  }
};
</script>

<style>
</style>